const topicSlug = {
    dane: 'steuern/',
    mzdy: 'lohne/',
    zahranici: 'ausland/',
    firemniPoradenstvi: 'beratung-von-firmen/',
    ucetnictvi: 'buchhaltung/',
    znaleckePosudky: 'gutachten/'
  }
  
  export const Index = {
    dane: {
      title: 'Steuern',
      slug: topicSlug.dane,
      description: `Möchten Sie die steuerlichen Auswirkungen einer geplanten Transaktion einschätzen, eine Steuererklärung oder eine Kontrollmeldung einreichen, brauchen Sie eine Vertretung vor dem Finanzamt oder müssen Sie ein anderes Steuerproblem lösen? Kontaktieren Sie uns – wir beraten Sie bei direkten und indirekten Steuern. Wir bieten auch schnelle telefonische Beratung. Auch die Analyse von Verrechnungspreisen ist für uns selbstverständlich.`,
      moreDE: true
    },
    mzdy: {
      title: 'Löhne',
      slug: topicSlug.mzdy,
      description: `Das  Verwalten der Personaldaten ist nicht nur administrativ äußerst anspruchsvoll, sondern birgt auch das Risiko, dass sich die Höhe der einzelnen Gehälter bei den Mitarbeitern herum spricht. Also auch aus diesem Grund lohnt es sich, die Lohn- und Gehaltsabrechnung auszulagern. Wir führen die Lohnbuchaltung so diskret wie möglich, maximal zuverlässig und pünktlich. Wir überwachen ständig die Entwicklung der Gesetzgebung, um sicherzustellen, dass wir die Löhne gemäß den neuesten Vorschriften berechnen.`,
      moreDE: true

    },
    zahranici: {
      title: 'Ausland',
      slug: topicSlug.zahranici,
      description: `Sind Ihre Geschäftspartner ausländische Unternehmen oder sogar Unternehmen in Ländern außerhalb der EU? Dann müssen Sie sich mit der Umsatzsteuer und in einigen Fällen auch mit Zöllen auseinander setzen. Zögern Sie nicht, uns zu kontaktieren, wir helfen Ihnen auch bei der Datenverarbeitung für Intrastat - von der Registrierung bis zu den Berichten selbst.`,
      moreDE: true

    },
    firemniPoradenstvi: {
      title: 'Beratung von Firmen',
      slug: topicSlug.firemniPoradenstvi,
      description: `Planen Sie den Umbau Ihres Unternehmens, die Liquidation oder eine Umstrukturierung? Lösen Sie die Probleme bei den Änderungen rechtzeitig mit unseren Fachleuten. Auf diese Weise können Sie sicher sein, dass alles korrekt, im gesetzlichen Rahmen und mit minimalen steuerlichen Konsequenzen durchgeführt wird. Wir sind auch auf den Einsatz von ERP-Systemen sowie die Suche und Eignungsprüfung von Kandidaten für Positionen in Ihren ökonomischen Abteilungen spezialisiert.`,
      moreDE: true

    },
    ucetnictvi: {
      title: 'Buchhaltung',
      slug: topicSlug.ucetnictvi,
      description:
        `Stresst Sie die Buchhaltung oder sind Sie unsicher, ob alles richtig gebucht wird? Wir entlasten Sie und helfen Ihnen beim Aufbau kompletter Buchhaltungsprozesse. Wir werden das Reporting und / oder Controlling nach Ihren Vorstellungen entwerfen und implementieren, was bei korrekter Verwendung zur Eingabe von Kosten, Aufträgen, Projekten usw. führt. Wir bieten Ihnen damit ein hervorragendes Werkzeug für das wirtschaftliche Management Ihres Unternehmens`,
      moreDE: true
      },
      
    znaleckePosudky: {
      title: 'Gutachten',
      slug: topicSlug.znaleckePosudky,
      description:
        `Führen Sie einen Zivilstreit oder ist sogar ein Strafverfahren anhängig und benötigen Sie ein Expertengutachten im Bereich Steuern oder Rechnungswesen? Lassen Sie uns ein Gutachten erstellen. Dies ist eine objektive und unabhängige Bewertung, die Sie als Beweismittel verwenden können. Es kommt häufig vor, dass die Streitparteien die Angelegenheit anders sehen, und es ist die Bewertung des Sachverständigen, die dazu beiträgt, den Fall weiter voranzutreiben`,
      moreDE: true
      }
  }
  export default Index
  