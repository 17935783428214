import React, { Fragment, useState } from 'react'
import Helmet from 'react-helmet'
import { StaticQuery, graphql } from 'gatsby'
import Meta from '../Default/Meta'
import DENav from './DENav'

import FooterDE from '../Default/FooterDE'
import { ToastContainer } from 'react-toastify'
import 'react-toastify/dist/ReactToastify.css'
import 'gatsby-plugin-breadcrumb/gatsby-plugin-breadcrumb.css'

import '../../../static/font/Raleway-Regular.ttf'
import './../../../static/font/Raleway-SemiBold.ttf'
import './../../../static/font/Raleway-Bold.ttf'
import 'modern-normalize/modern-normalize.css'
import './../Default/globalStyles.css'
import './../Grid/grid.css'

export default ({ children, meta, title }) => {
  return (
    <StaticQuery
      query={graphql`
        query IndexDELayoutQuery {
          settingsYaml {
            siteTitle
            siteDescription
            googleTrackingId
            socialMediaCard {
              image
            }
          }
          allPosts: allMarkdownRemark(
            filter: { fields: { contentType: { eq: "postCategories" } } }
            sort: { order: DESC, fields: [frontmatter___date] }
          ) {
            edges {
              node {
                fields {
                  slug
                }
                frontmatter {
                  title
                }
              }
            }
          }
        }
      `}
      render={data => {
        const { siteTitle, socialMediaCard, googleTrackingId } =
          data.settingsYaml || {}
        return (
          <Fragment>
            <Helmet defaultTitle={siteTitle} titleTemplate={`%s ${siteTitle}`}>
              {title}
              <html lang="de" />
              <link
                href="https://fonts.googleapis.com/css2?family=Raleway:wght@400;600;700&display=swap"
                rel="stylesheet"
              ></link>
            </Helmet>
            <Meta
              googleTrackingId={googleTrackingId}
              absoluteImageUrl={
                socialMediaCard &&
                socialMediaCard.image &&
                socialMediaCard.image
              }
              {...meta}
              {...data.settingsYaml}
            />
            <ToastContainer />
            <DENav />
            <Fragment>
              <div style={{ marginTop: '8rem' }}>{children}</div>
            </Fragment>
            <FooterDE />
          </Fragment>
        )
      }}
    />
  )
}
